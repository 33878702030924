<template>
  <div id="examList">
    <v-card>
      <!-- HEADER TOOLBAR -->
      <v-toolbar class="page-primary-card-header">
        <v-list-item dark>
          <v-list-item-avatar color="primary lighten-2">
            <v-icon>{{ card_header_props.header.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              card_header_props.header.headLine
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              card_header_props.header.subTitle
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn
          @click="
            card_header_props.helper.page_helper =
              !card_header_props.helper.page_helper
          "
          icon
          dark
        >
          <v-icon>info</v-icon>
        </v-btn>

        <v-dialog
          v-model="card_header_props.helper.page_helper"
          width="500"
          :active.sync="card_header_props.helper.page_helper"
        >
          <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>{{
              this.$t("_common.How_can_I_use_this_form")
            }}</v-card-title>

            <v-card-text>{{ this.$t("_exam_list._info.content") }}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="card_header_props.helper.page_helper = false"
                >{{ this.$t("_common.OK") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <!-- HEADER TOOLBAR -->
      <v-container class="mx-0 col-12">
        <v-row>
          <v-col cols="4">
            <v-select
              solo
              :items="$goc.ENUMS.ExamListTypes"
              item-text="text"
              item-value="val"
              :label="this.$t('_exam_results.Exam_filter')"
              v-model="examType"
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <!-- MAIN VIEW -->
      <v-flex>
        <v-sheet
          class="mx-auto"
          elevation="8"
          style="width: 100%"
          v-if="exams.length > 0"
        >
          <div v-for="examKey in examGroupKeys" :key="`exam-w-${examKey}`">
            <div class="title ml-2">
              {{ examKey.split("_").reverse().join(".") }}
              {{ $t("_exam_list.Exams") }}
            </div>
            <v-slide-group
              v-model="model"
              class="pa-4"
              center-active
              show-arrows
              :key="`exam-${examKey}`"
            >
              <v-slide-item
                v-for="(exam, i) in examGroupList[examKey]"
                :key="`exam-${examKey}-${exam.Exam.Id}-${i}`"
                v-slot:default="{ active, toggle }"
                :style="shortExamList(examGroupList[examKey])"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-flex style="padding: 0 10px 20px 10px; max-width: 250px">
                    <v-card
                      class="mx-auto"
                      height="290"
                      width="250"
                      :disabled="
                        examDisabled(
                          exam.Exam.ActivationDate,
                          exam.Exam.ExpirationDate,
                          exam.FinishDate
                        )
                      "
                      @click="
                        examDisabled(
                          exam.Exam.ActivationDate,
                          exam.Exam.ExpirationDate,
                          exam.FinishDate
                        )
                          ? undefined
                          : onClickExam(exam.Exam.Id)
                      "
                    >
                      <v-img :aspect-ratio="16 / 9" src="/assets/noimage.png">
                        <v-avatar
                          v-if="
                            hover &&
                            !examDisabled(
                              exam.Exam.ActivationDate,
                              exam.Exam.ExpirationDate,
                              exam.FinishDate
                            )
                          "
                          color="white"
                          size="36"
                          class="centered"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            margin: auto;
                          "
                        >
                          <v-icon color="black">mdi-pencil</v-icon>
                        </v-avatar>
                        <v-avatar size="25"></v-avatar>
                      </v-img>
                      <v-card-text class="pt-6" style="position: relative">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <h5 class="truncate font-semibold" v-on="on">
                              {{ exam.Exam.Title }}
                            </h5>
                          </template>
                          <span>{{ $t("_exam_list.ClickToAttendExam") }}</span>
                        </v-tooltip>
                        <div class="font-semibold truncate-long">
                          {{ $t("_create_content._exam.Exam_started_at") }}
                        </div>
                        <div>
                          <b>{{
                            exam.Exam.ActivationDate
                              | moment("dddd, MMMM Do YYYY")
                          }}</b>
                        </div>
                        <div>
                          {{ exam.Exam.ActivationDate | moment("h:mm:ss a") }}
                        </div>

                        <div class="font-semibold truncate-long">
                          {{ $t("_create_content._exam.Exam_finished_at") }}
                        </div>
                        <div>
                          <b>{{
                            exam.Exam.ExpirationDate
                              | moment("dddd, MMMM Do YYYY")
                          }}</b>
                        </div>
                        <div>
                          {{ exam.Exam.ExpirationDate | moment("h:mm:ss a") }}
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-hover>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-sheet>
        <v-card style="margin-top: 10px" v-else class="mx-auto" elevation="0">
          <v-card-text>
            <p class="display-1 text--primary text-center">
              {{ this.$t("_exam_list.No_exams_found") }}
            </p>
            <p>{{ this.$t("_exam_list.No_exams_found_Text") }}</p>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
require("../../assets/css/neo.css");

export default {
  data() {
    return {
      model: null,
      examGroupKeys: [],
      examGroupList: {},
      examType: { val: 1 },
      card_header_props: {
        header: {
          headLine: this.$t("_verticalNavbar.Exam_List"),
          subTitle: this.$t("_exam_list.You_can_select_and_start_an_exam"),
          icon: "mdi-format-list-bulleted",
        },
        helper: {
          absolute: true,
          opacity: 0.8,
          overlay: false,
          page_helper: false,
        },
      },
      exams: [],
    };
  },
  methods: {
    init() {
      this.getAssignedExams();
    },
    shortExamList(exams) {
      if (exams.length < 4) {
        return "margin-left: 52px;";
      } else {
        return "margin-left: 0px;";
      }
    },
    examDisabled(activationDate, expirationDate, finishDate) {
      if (activationDate == null || typeof activationDate == "undefined") {
        return false;
      } else if (
        expirationDate == null ||
        typeof expirationDate == "undefined"
      ) {
        return false;
      } else if (finishDate == null || typeof finishDate == "undefined") {
        return false;
      } else {
        const aDate = new Date(activationDate).getTime();
        const eDate = new Date(expirationDate).getTime();
        const fDate = new Date(finishDate).getTime();
        let today = new Date().getTime();
        return !(eDate > today && today > aDate) || fDate > 0;
      }
    },
    getAssignedExams() {
      let _self = this;
      let userId = JSON.parse(localStorage.getItem("userInfo")).Uid;
      let groupObj = {};
      let query = "?" + `userId=${userId}&` + `examType=${this.examType.val}`;
      this.$goc.request.get(this.$enums.API.Exam_User + query, (response) => {
        let examsReverse = {};
        if (response.Result != undefined) {
          examsReverse = response.Result.Exams.reverse();
          examsReverse.map((e) => {
            let key = _self.$moment(e.Exam.AssignedDate).format("YYYY_MM");
            return groupObj[key]
              ? groupObj[key].push(e)
              : (groupObj[key] = [e]);
          });
          _self.examGroupKeys = Object.keys(groupObj).sort((a, b) => {
            const date1 = +a.replace(/\_/g, "");
            const date2 = +b.replace(/\_/g, "");
            if (date1 < date2) return 1;
            if (date1 > date2) return -1;
            return 0;
          });
        }
        _self.examGroupList = groupObj;
        _self.exams = examsReverse;
      });
    },
    onClickExam(exam) {
      this.$router.push({
        name: "exam-attend-specific",
        params: { examId: parseInt(exam) },
      });
    },
    fancyTimeFormat() {},
  },
  mounted: function () {
    this.init();
  },
  watch: {
    examType() {
      this.getAssignedExams();
    },
  },
  name: "ExamList",
};
</script>

<style scoped>
</style>
